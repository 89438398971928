import React from "react"
import { graphql, Link } from "gatsby"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { pluralizeName, getMonthYearFromStrings } from "../utils/utils"
import { Image, Text } from "../components/Core"
import { Button, ButtonGroup } from "../components/Button"
import { Columns, Section } from "../components/Containers"

const SingleReview = ({ data, pageContext, location }) => {
  const post = data.singleReview.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext

  const getStartedData = data.allTemplateSettingsJson.nodes[0].getStarted
  const getStartedDataEs =
    data.allTemplateSettingsJson.nodes[0].getStartedSpanish

  const reviewPool = data.relatedReviews.nodes
  // let referrerPool = data.relatedReviewsProviders.nodes

  const relatedReview = reviewPool.find(
    rev =>
      rev.mainProcedureDone && rev.mainProcedureDone === post.mainProcedureDone
  )

  let backUrl = ""

  if (data.allUniquePagesJson?.nodes[0]?.title) {
    backUrl = `/${data.allUniquePagesJson.nodes[0].title}`
  }

  return (
    <Layout navSpacer language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <div className="single-review" style={{ paddingBottom: 0 }}>
        <Section zeroBottom smallSpacing zeroTop>
          <Columns sideColumnsSize={2} className="px-40--mobile">
            <Button
              href="/video-reviews/"
              buttonText="Back to Video Reviews"
              secondary
              goBack
            />
          </Columns>
        </Section>

        <Section>
          <Columns sideColumnsSize={2}>
            <div className="column is-one-third px-40--mobile">
              <Text as="h1" text={`Meet ${post.reviewerName}`} />
              <Text className="px-0" as="p" text={post.subheading} />

              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                // overVideo
                // iconOnly
                language={language}
                buttonClass="contained"></TheaterVideo>
            </div>
            <div className="column is-1"></div>
            <div className="column mt-3--mobile px-40--mobile">
              <Image publicId={post.thumbnailPublicId} />
            </div>
          </Columns>
        </Section>

        <Section>
          <Columns sideColumnsSize={2}>
            <div className="column">
              <Text
                as="h2"
                text={`${pluralizeName(post.reviewerName)} Story`}
              />
              <Text className="mt-2" text={post.quote} />
              {post.proceduresDone && post.proceduresDone.length >= 1 && (
                <ButtonGroup className="px-40--mobile">
                  {post.proceduresDone.map(p => (
                    <Button
                      key={p.procedureDone.procedureUrl}
                      buttonText={`Learn More about ${p.procedureDone.procedureName}`}
                      href={`/${p.procedureDone.procedureUrl}`}
                      contained
                      destination="internal"
                    />
                  ))}
                </ButtonGroup>
              )}
            </div>
          </Columns>
        </Section>

        {relatedReview && (
          <Section>
            <Columns sideColumnsSize={2}>
              <div className="column px-40--mobile">
                <>
                  <Link
                    to={`/${relatedReview.title}`}
                    className="single-review__related">
                    <div className="single-review__related--content">
                      <h4 className="mb-2--mobile px-0">{`Watch other reviews like ${pluralizeName(
                        post.reviewerName
                      )}`}</h4>

                      <div
                        style={{ gap: "20px" }}
                        className="flex align-center">
                        <Text
                          className="bold mb-0 mb-1--mobile mt-0 px-0"
                          as="p"
                          text={`Meet ${relatedReview.reviewerName}`}
                        />
                        <Text
                          className="mb-0 mt-0 px-0  is-hidden-touch"
                          as="p"
                          text={relatedReview.mainProcedureDone}
                        />
                        <Text
                          className="mb-0 mt-0 px-0  is-hidden-touch"
                          as="p"
                          text={getMonthYearFromStrings(
                            relatedReview.monthYear.year,
                            relatedReview.monthYear.month
                          )}
                        />
                      </div>
                    </div>

                    <div>
                      <Image publicId={relatedReview.thumbnailPublicId} />
                      <div className="is-hidden-desktop">
                        <div
                          style={{
                            gap: "20px",
                            justifyContent: "space-between"
                          }}
                          className="flex align-center">
                          <Text
                            className="mb-0 mt-1 px-0"
                            as="p"
                            text={relatedReview.mainProcedureDone}
                          />
                          <Text
                            className="mb-0 mt-1 px-0"
                            as="p"
                            text={getMonthYearFromStrings(
                              relatedReview.monthYear.year,
                              relatedReview.monthYear.month
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
              </div>
            </Columns>
          </Section>
        )}

        <Section zeroBottom className="single-review__cta">
          <Columns sideColumnsSize={2}>
            <div className="column single-review__cta--content">
              <Text
                style={{ fontSize: "38px" }}
                as="h3"
                text={getStartedData.blurb}
              />
              <ButtonGroup className="px-40--mobile">
                {getStartedData.buttons.map(({ button }) => (
                  <Button
                    key={button.href}
                    buttonText={button.buttonText}
                    href={button.href}
                    destination={button.destination}
                    contained
                  />
                ))}
              </ButtonGroup>
            </div>
            <div style={{ marginTop: "auto" }} className="column mt-3--mobile">
              <div>
                <Image publicId="77P/DEV/about-doctor-fan" />
              </div>
            </div>
          </Columns>
        </Section>
      </div>
    </Layout>
  )
}

export default SingleReview

export const query = graphql`
  query ($id: String!, $language: String!, $title: String!) {
    procedureQueryPage: allUniquePagesJson(
      filter: { template: { eq: "procedures" }, language: { eq: $language } }
    ) {
      nodes {
        title
      }
    }
    allUniquePagesJson(
      filter: {
        template: { eq: "patient-stories" }
        language: { eq: $language }
      }
    ) {
      nodes {
        title
      }
    }
    allTemplateSettingsJson(filter: { templateType: { eq: "single-review" } }) {
      nodes {
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              appearance
              destination
              href
              buttonText
            }
          }
        }
        getStartedSpanish {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              appearance
              destination
              href
              buttonText
            }
          }
        }
      }
    }
    singleReview: allReviewsJson(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
            procedureShortName
          }
        }
        heading
        quote
        language
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
      }
    }
    relatedReviews: allReviewsJson(
      filter: {
        reviewType: { eq: "Patient" }
        language: { eq: "en" }
        title: { ne: $title }
      }
    ) {
      nodes {
        thumbnailPublicId
        title
        reviewerName
        reviewType
        mainProcedureDone
        otherProcedureName
        monthYear {
          month
          year
        }
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
      }
    }
    relatedReviewsProviders: allReviewsJson(
      filter: {
        reviewType: { eq: "Provider" }
        language: { eq: "en" }
        title: { ne: $title }
      }
    ) {
      nodes {
        thumbnailPublicId
        title
        reviewerName
        reviewType
        mainProcedureDone
        monthYear {
          month
          year
        }
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
      }
    }
  }
`
